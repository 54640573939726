import styled from '@emotion/styled';
import { Colors } from './globalStyles';

const SiteHeader = styled.header`
  color: ${Colors.white};
  background: ${Colors.black};
`;

const SiteNav = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  display: grid;
  grid-template-rows: 60px;
  grid-template-columns: 70% 30%;
  @media (min-width: 768px) {
    grid-template-columns: 40% 60%;
  }
`;

const SiteTitle = styled.h1`
  font-size: 30px;
  line-height: 60px;
  margin: 0;
  a {
    color: ${Colors.white};
    text-decoration: none;
  }
`;

const SiteLinks = styled.div`
  padding-top: 15px;
  text-align: right;
  @media (min-width: 1120px) {
    padding-top: 0;
  }
  a {
    color: ${Colors.white};
    text-decoration: none;
    text-align: center;

    &.active {
      font-weight: bold;
    }
  }
`;

const ShowMobile = styled.div`
  display: block;
  @media (min-width: 1120px) {
    display: none;
  }
`;

const HideMobile = styled.div`
  display: none;
  @media (min-width: 1120px) {
    display: block;
  }
`;

const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: white;
`;

const MenuLinks = styled.ul`
  list-style: none;
`;

const MenuLink = styled.li`
  padding: 15px;
  display: block;
  text-align: center;
  @media (min-width: 768px) {
    display: inline-block;
  }

  a {
    display: block;
    padding: 7px;

    &.highlight {
      color: ${Colors.red};
    }
  }
`;

const MobileMenuBackground = styled.section`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.black};
  color: ${Colors.white};
`;

const MobileMenuWrapper = styled.nav`
  position: relative;
  margin-top: 45px;
`;

const MobileMenuCloseButton = styled.button`
  position: absolute;
  top: -30px;
  right: 30px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
`;

export {
  SiteHeader,
  SiteNav,
  SiteTitle,
  SiteLinks,
  ShowMobile,
  HideMobile,
  MenuButton,
  MenuLink,
  MenuLinks,
  MobileMenuBackground,
  MobileMenuWrapper,
  MobileMenuCloseButton,
};
