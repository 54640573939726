import React, { Component } from 'react';
import Typewriter from 'typewriter-effect';
import {
  Container,
  CardBodySplit,
  Sub,
  SubHeading,
  Button,
  FullPanel,
  PaddedBox,
  BackgroundDiv,
} from '../styles/globalStyles';
import FlagTiles from '../components/flagTiles';
import { ContactSection, ContactDiv } from '../styles/contactStyles';
import { HelpCircle, Coffee, Award, Speaker, CheckCircle } from 'react-feather';
import { StaticQuery, graphql } from 'gatsby';
import encode from '../util/encode';
import ImageCambridge from '../images/image-cambridge';

const icons = {
  Speaker,
  HelpCircle,
  Award,
  Coffee,
};

const SuccessMessage = () => (
  <FullPanel>
    <CheckCircle /> Your message has been successfully submitted.
  </FullPanel>
);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copy: '',
      name: '',
      subject: '',
      contact: '',
      message: '',
      success: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    this.isTileSelected = this.isTileSelected.bind(this);
  }
  componentDidMount() {
    const { copy, title } = this.props.data[0].node.content;
    this.setCopy(copy, title);
  }
  setCopy(copy, subject) {
    this.setState({
      copy,
      subject,
    });
  }
  isTileSelected(title) {
    return this.state.subject === title;
  }
  handleNameChange(event) {
    if (!this.state.success) {
      this.setState({ name: event.target.value });
    }
  }
  handleContactChange(event) {
    if (!this.state.success) {
      this.setState({ contact: event.target.value });
    }
  }
  handleSubjectChange(event) {
    if (!this.state.success) {
      this.setState({ subject: event.target.value });
    }
  }
  handleMessageChange(event) {
    if (!this.state.success) {
      this.setState({ message: event.target.value });
    }
  }
  submitMessage(event) {
    event.preventDefault();
    if (this.state.name && this.state.contact && this.state.message) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...this.state }),
      })
        .then(() => this.setState({ success: true }))
        .catch(console.error);
    } else {
      this.setState({
        copy: 'Please fill in Name, Contact and Message',
      });
    }
  }
  render() {
    return (
      <ContactSection>
        <BackgroundDiv>
          <ImageCambridge />
        </BackgroundDiv>
        <Container>
          <CardBodySplit>
            <ContactDiv>
              <SubHeading>Contact Me</SubHeading>
              <h3>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('Hola.')
                      .pauseFor(2500)
                      .deleteChars(5)
                      .typeString('Vanakkam.')
                      .pauseFor(2500)
                      .deleteChars(9)
                      .typeString('Salaam.')
                      .pauseFor(2500)
                      .deleteChars(7)
                      .typeString('Hello.')
                      .pauseFor(2500)
                      .deleteChars(6)
                      .typeString('Bonjour.')
                      .pauseFor(2500)
                      .deleteChars(8)
                      .typeString('Ciao.')
                      .pauseFor(2500)
                      .deleteChars(5)
                      .typeString('Aloha.')
                      .pauseFor(2500)
                      .deleteChars(6)
                      .typeString('Ahlan wa sahlan.')
                      .pauseFor(2500)
                      .deleteChars(16)
                      .start();
                  }}
                />
              </h3>
              <p>Please use the form to get in touch.</p>
              <p>Here's a handy list of subject lines to get you started.</p>
              {this.props.data.map(
                ({
                  node: {
                    content: { title, copy, icon },
                  },
                }) => {
                  const IconComponent = icons[icon];

                  return (
                    <FlagTiles
                      selected={this.isTileSelected(title)}
                      key={icon}
                      icon={<IconComponent size={45} />}
                      iconSize={45}
                      title={title}
                      callback={this.setCopy.bind(this, copy, title)}
                      hover="#222222"
                    />
                  );
                }
              )}
              <Sub>
                If you prefer email, address them to hello [at] domain name.
              </Sub>
            </ContactDiv>
            <ContactDiv>
              {this.state.success ? <SuccessMessage /> : null}
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                {this.state.copy ? (
                  <PaddedBox style={{ borderRadius: '8px' }}>
                    {this.state.copy}
                  </PaddedBox>
                ) : null}
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
                <label htmlFor="contact">Contact</label>
                <input
                  id="Email"
                  type="email"
                  name="email"
                  value={this.state.contact}
                  onChange={this.handleContactChange}
                />
                <label htmlFor="subject">Subject</label>
                <input
                  id="subject"
                  type="text"
                  name="subject"
                  value={this.state.subject}
                  onChange={this.handleSubjectChange}
                />
                <input type="hidden" name="form-name" value="contact" />
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleMessageChange}
                />
                <Button onClick={this.submitMessage}>Send Message</Button>
              </form>
            </ContactDiv>
          </CardBodySplit>
        </Container>
      </ContactSection>
    );
  }
}

const ContactQuery = () => (
  <StaticQuery
    query={graphql`
      query allContactsQuery {
        allContactsJson {
          edges {
            node {
              content {
                title
                copy
                icon
              }
            }
          }
        }
      }
    `}
    render={(data) => <Contact data={data.allContactsJson.edges} />}
  />
);

export default ContactQuery;
