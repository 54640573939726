import React from 'react';
import { Link } from 'gatsby';
import { MenuLinks, MenuLink } from '../styles/headerStyles';

const DesktopMenu = ({ menuItems }) => (
  <MenuLinks>
    {menuItems.map((item) => (
      <MenuLink key={item.path}>
        <Link to={item.path}>{item.name}</Link>
      </MenuLink>
    ))}
    <MenuLink key="calendly">
      <Link
        to="https://calendly.com/vinayraghu"
        className="highlight"
        target="_blank"
      >
        Schedule a Call
      </Link>
    </MenuLink>
  </MenuLinks>
);

export default DesktopMenu;
